<template>
  <v-container fluid>
    <v-card outlined class="mt-2 mx-4">
      <v-card-title class="text-body-1 px-3 py-2 lightgray">
        <span class="pl-5 text-h6"> Endereços </span>
        <v-spacer></v-spacer>
        <v-btn
          class="white--text mt-6 mt-sm-0"
          color="button_1"
          small
          @click="openDialogEnderecos"
        >
          <v-icon left>mdi-plus</v-icon>
          Endereço
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-0">
        <v-data-table
          v-if="!loading"
          :headers="headers"
          :items="enderecos"
          :items-per-page="-1"
          item-key="id"
          sort-by="id"
          hide-default-footer
          class="data-tables data-tables__row-click"
          @click:row="openEndereco"
        >
          <template v-slot:item.actions="{ item }">
            {{ item.status ? "Ativo" : "Inativo" }}
          </template>

          <template v-slot:item.principal="{ item }">
            <v-btn
              small
              icon
              :disabled="item.status === 0 ? true : false"
              :color="item.end_principal ? 'button_2' : ''"
              @click.stop="
                item.end_principal ? '' : atualizaEnderecoFavorito(item)
              "
            >
              <v-icon>{{
                item.end_principal ? "mdi-star" : "mdi-star-outline"
              }}</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <Carregando v-else />
      </v-card-text>
      <DialogEnderecos
        v-if="dialogEnderecos"
        :dialogEnderecos.sync="dialogEnderecos"
        :edit="edit"
        :item="endereco"
        :cliente_id="cliente_id"
        @fetch-enderecos="getEnderecos"
      />
    </v-card>
  </v-container>
</template>

<script>
import {
  fetchEnderecosClientes,
  putEnderecoFavorito,
} from "@/api/clientes/clientes_enderecos.js";

export default {
  name: "DadosEndereco",

  props: {
    cliente_id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      enderecos: [],
      endereco: {},
      original: {},
      edit: false,
      dialogEnderecos: false,
      loading: false,
    };
  },

  components: {
    DialogEnderecos: () => import("./DialogEnderecos.vue"),
  },

  computed: {
    headers() {
      return [
        {
          text: "Rua",
          value: "rua",
          divider: true,
        },
        {
          text: "Número",
          value: "numero",
          divider: true,
        },
        {
          text: "Cidade",
          value: "cidade_nome",
          divider: true,
        },
        {
          text: "Estado",
          value: "estado",
          divider: true,
        },
        {
          text: "Bairro",
          value: "bairro",
          divider: true,
        },
        {
          text: "País",
          align: "center",
          value: "pais",
          divider: true,
        },
        {
          text: "Endereço Padrão",
          align: "center",
          value: "principal",
          divider: true,
        },
        {
          text: "Status",
          align: "center",
          value: "actions",
        },
      ];
    },
  },
  methods: {
    openDialogEnderecos() {
      this.edit = false;
      this.dialogEnderecos = true;
    },
    getEnderecos() {
      this.loading = true;
      fetchEnderecosClientes(this.cliente_id)
        .then((response) => {
          this.enderecos = response;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    openEndereco(item) {
      this.edit = true;
      this.endereco = item;
      this.dialogEnderecos = true;
    },

    atualizaEnderecoFavorito(item) {
      this.loading = true;

      let cliente = {};

      cliente.cliente_id = this.cliente_id;

      putEnderecoFavorito(item.id, cliente)
        .then((response) => {
          if (response.status === 200) {
            this.$toast.success(`${this.$tc("global.atualizadosucesso")}`);
            this.getEnderecos();
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getEnderecos();
  },
};
</script>

<style></style>
